<template>
  <div class="brandInformation">
    <header>新闻资讯</header>
    <!-- 背景图 -->
    <div class="content">
      <div class="lists">
        <div class="list" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
          <div
            class="list-img"
            :style="[
              {
                backgroundImage: 'url(' + baseurl + item.img + ')',
              },
            ]"
          ></div>
          <div class="list-time">{{ item.create_time }}</div>
          <div class="list-title">{{ item.title }}</div>
        </div>
      </div>
      <div class="page-wraper" v-if="total">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="9"
          @current-change="currenChange"
          @prev-click="prevChange"
          @next-click="nextChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { articleList } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      page: 1,
      dataList: [],
    };
  },
  mounted() {
    this.articleList();
  },
  methods: {
    articleList() {
      articleList({
        p: this.page,
      }).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
          this.total = res.total
        }
      });
    },
    currenChange(e) {
      this.page = e;
      this.articleList();
    },
    prevChange(e) {
      this.page = e;
      this.articleList();
    },
    nextChange(e) {
      this.page = e;
      this.articleList();
    },
    toDetail(id) {
      this.$router.push({
        path:'/brandInformationDetail',
        query:{
          id:id
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.brandInformation {
  width: 100%;
  header {
    width: 1200px;
    margin: 0 auto;
    padding-left: 30px;
    font-size: 36px;
    font-weight: 700;
    color: #000;
    height: 120px;
    line-height: 120px;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    .lists {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      .list {
        width: 375px;
        height: 400px;
        cursor: pointer;
        .list-img {
          width: 375px;
          height: 210px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        .list-time {
          font-size: 16px;
          color: #000;
          margin-top: 28px;
        }
        .list-title {
          font-size: 24px;
          color: #000;
          margin-top: 16px;
          font-weight: 550;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
    .page-wraper{
      display: flex;
      justify-content: center;
      margin: 40px auto 60px;
    }
  }
}
</style>
